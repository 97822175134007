import jennifer_montgomery from './jennifer_montgomery.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={jennifer_montgomery} className="App-logo" alt="logo" />
        <p>
          Jen Montgomery's Info
        </p>
        <a
          className="App-link"
          href="https://www.linkedin.com/in/montgomeryjennifer/"
          target="_blank"
          rel="noopener noreferrer"
        > View LinkedIn profile
        </a>
      </header>
    </div>
  );
}

export default App;
